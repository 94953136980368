import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { FindYourSpecialist } from '../organisms/NarrativnayaPsihoterapiya/FindYourSpecialist'
import { HeroBlock } from '../organisms/NarrativnayaPsihoterapiya/HeroBlock'
import { HowItSection } from '../organisms/HowItSection'
import { NarrativeInYoutalk } from '../organisms/NarrativnayaPsihoterapiya/NarrativeInYoutalk'
import { NarrativePractice } from '../organisms/NarrativnayaPsihoterapiya/NarrativePractice'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { PsychologistsWithRazvod } from '../organisms/NarrativnayaPsihoterapiya/PsychologistsWithRazvod'
import { QueriesNarrative } from '../organisms/NarrativnayaPsihoterapiya/QueriesNarrative'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { StepsToFind } from '../organisms/NarrativnayaPsihoterapiya/StepsToFind'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { WhatIsNarrative } from '../organisms/NarrativnayaPsihoterapiya/WhatIsNarrative'
import { WhoWillSuiteNarrative } from '../organisms/NarrativnayaPsihoterapiya/WhoWillSuiteNarrative'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const NarrativnayaPsihoterapiya = ({ data }) => (
  <App>
    <SEO
      description="Психологи специалисты по нарративной психотерапии онлайн ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Нарративная психотерапия онлайн — консультации психолога специалиста | YouTalk"
    >
      <ProductMicromarkupComponent name="Нарративная терапия онлайн" />
    </SEO>
    <Page>
      <Header />
      <Main>
        <HeroBlock />
        <WhatIsNarrative />
        <NarrativePractice />
        <WhoWillSuiteNarrative />
        <QueriesNarrative />
        <StepsToFind />
        <TariffBlock />
        <TarrifPackages />
        <PsychologistsWithRazvod data={pick(['psychologists'], data)} />
        <NarrativeInYoutalk />
        <FindYourSpecialist />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default NarrativnayaPsihoterapiya

export const query = graphql`
  query NarrativnayaPsihoterapiya {
    psychologists: allPsychologistsData(
      filter: {
        is_active: { in: true }
        approaches: { elemMatch: { name: { in: "Нарративная психотерапия" } } }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
